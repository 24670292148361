<script setup lang="ts">
export type JamBasePillVariant =
    | 'gray'
    | 'bordered'
    | 'blue'
    | 'dark-blue'
    | 'green'
    | 'light-green'
    | 'dark-green'
    | 'yellow'
    | 'red'
    | 'dark';

export type JamBasePillSize = 'large' | 'medium' | 'small';
export type JamBasePillStroke = 'thick' | 'thin';

const props = defineProps({
    callbackClick: {
        default: null,
        type: Function,
    },
    callbackClose: {
        default: null,
        type: Function,
    },
    closeButton: {
        default: false,
        type: Boolean,
    },
    disabled: {
        default: false,
        type: Boolean,
    },
    iconBefore: {
        default: '',
        type: String,
    },
    iconMenu: {
        default: false,
        type: Boolean,
    },
    isRounded: {
        default: false,
        type: Boolean,
    },
    shrink: {
        default: true,
        type: Boolean,
    },
    size: {
        default: 'large',
        type: String as PropType<JamBasePillSize>,
    },
    state: {
        default: 'default',
        type: String as PropType<'default' | 'active'>,
    },
    stroke: {
        default: 'thick',
        type: String as PropType<JamBasePillStroke>,
    },
    variant: {
        default: 'gray',
        type: String as PropType<JamBasePillVariant>,
    },
});

const isVariantDark = computed(() => {
    return ['dark', 'dark-blue', 'dark-green'].includes(props.variant);
});

const isFullRounded = computed(() => {
    return ['large', 'medium'].includes(props.size) || props.isRounded;
});

const isUppercase = computed(() => {
    return ['small'].includes(props.size) && !props.isRounded;
});

const isInteractive = computed(() => {
    return props.callbackClick !== null;
});

const isDisabled = computed(() => {
    return props.disabled;
});

const handleClick = () => {
    if (!isInteractive.value || !props.callbackClick) {
        return;
    }

    props.callbackClick();
};

const handleClose = () => {
    if (props.callbackClose) {
        props.callbackClose();
    }
};
</script>

<template>
    <div
        class="inline-flex items-center justify-center"
        :class="{
            'bg-blue-50 text-blue-800': variant === 'blue',
            'bg-gray-900 text-white': variant === 'dark',
            'bg-blue-600 text-white': variant === 'dark-blue',
            'bg-green-800 text-white': variant === 'dark-green',
            'bg-gray-50': variant === 'gray',
            'bg-green-100 text-green-800': variant === 'green',
            'bg-green-600 text-white': variant === 'light-green',
            'bg-red-100 text-red-700': variant === 'red',
            'bg-yellow-100 text-yellow-900': variant === 'yellow',
            'border bg-white': variant === 'bordered',
            'text-gray-900':
                (variant === 'bordered' && state !== 'active') ||
                (variant === 'gray' && !isDisabled),
            'border-green-800 text-green-800':
                variant === 'bordered' && state === 'active',

            rounded: size === 'small',
            'rounded-full': isFullRounded,
            'text-lg leading-6': size === 'large',
            'text-sm leading-6': size === 'medium',
            'text-xs leading-3': size === 'small',

            'focus:outline focus:outline-offset-4 focus:outline-blue-600':
                isInteractive,

            'opacity-50': isDisabled,
            'shrink-0': !shrink,
            'text-gray-500': isDisabled && variant === 'gray',
        }"
    >
        <Component
            :is="isInteractive ? 'button' : 'div'"
            class="flex items-center truncate transition-all"
            :class="{
                'rounded-l ps-2': !isFullRounded,
                'rounded-l-full ps-3': isFullRounded,
                'rounded-r pe-2': !isFullRounded && !closeButton,
                'rounded-r-full pe-3':
                    isFullRounded && !closeButton && !iconMenu,
                'rounded-r-full pe-0':
                    isFullRounded && !closeButton && iconMenu,

                'pe-1': closeButton && !isFullRounded && !isInteractive,
                'pe-2': closeButton && (isFullRounded || isInteractive),
                'pe-3': closeButton && isFullRounded && isInteractive,

                'border-r': isInteractive && closeButton && isInteractive,
                'border-r-black/10':
                    isInteractive && closeButton && !isVariantDark,
                'border-r-white/15':
                    isInteractive && closeButton && isVariantDark,

                'hover:border-r-transparent hover:bg-black/10':
                    isInteractive && !isDisabled && !isVariantDark,
                'hover:border-r-transparent hover:bg-white/15':
                    isInteractive && !isDisabled && isVariantDark,

                'cursor-not-allowed': isInteractive && isDisabled,

                'py-2': size === 'large' || size === 'medium',
                'py-1': size === 'small' && !iconBefore,
            }"
            :disabled="isInteractive && isDisabled"
            @click="handleClick"
        >
            <JamBaseIcon
                v-if="iconBefore"
                :icon-name="iconBefore"
                :stroke="stroke"
                :size="size"
            />
            <div
                class="max-w-full"
                :class="[
                    isUppercase ? 'uppercase' : '',
                    size === 'small' && iconBefore && 'ps-1',
                ]"
            >
                <slot />
            </div>
            <JamBaseIcon
                v-if="iconMenu"
                class="mx-2 flex items-center justify-center"
                icon-name="menu-dots-gray"
                stroke="thin"
                size="large"
            />
        </Component>
        <button
            v-if="closeButton"
            :disabled="isDisabled"
            :data-disabled="isDisabled"
            class="m-0 self-stretch py-2 transition-all"
            :class="{
                'rounded-r pe-1 ps-1': !isFullRounded,
                'rounded-r-full pe-3 ps-2': isFullRounded,
                'hover:bg-black/10': !isDisabled && !isVariantDark,
                'hover:bg-white/15': !isDisabled && isVariantDark,
                'cursor-not-allowed': isDisabled,
            }"
            type="button"
            @click="handleClose"
        >
            <JamBaseIcon icon-name="close" :size="size" />
        </button>
    </div>
</template>
